import React, { useState } from "react";
import { 
    GlobalStyles, 
    CssBaseline, 
    AppBar, 
    Toolbar, 
    Button, 
    Typography, 
    Container, 
    Stack, 
    Paper, 
    TextField, 
    MenuItem, 
    InputLabel, 
    FormControl, 
    Select
} from "@mui/material";
import TableData from "../../components/Table";
import Loading from "../../components/Loading"
import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"
import axios from '../../api';
import Swal from "sweetalert2";
import { isLogged, removeUser } from "../../utils";
import Footer from "../../components/Footer";
import logo from '../../images/red-thunder.png'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';

const BO = () => {
    const dateFrom = new Date()
    const dateTo = new Date()
    dateTo.setDate(dateTo.getDate() + 1)

    let dateF = new Date(dateFrom.toLocaleDateString())
    let dateT = new Date(dateTo.toLocaleDateString())
    let dfSplit = dateF.toISOString().split('T')
    let dtSplit = dateT.toISOString().split('T')

    const [selectedDateFrom, setSelectedDateFrom] = useState(dfSplit[0])
    const [selectedDateTo, setSelectedDateTo] = useState(dtSplit[0])
    const [accountType, setAccountType] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState([])

    const handleDateFromChange = (event) => {
        setSelectedDateFrom(event.target.value)
    }

    const handleDateToChange = (event) => {
        setSelectedDateTo(event.target.value)
    }

    const handleChangeType = (e) => {
        setAccountType(e.target.value)
    }

    const [timeFrom, setTimeFrom] = useState(dayjs().set('hour', 6).set('minute', 0).set('second', 0));
    const [timeTo, setTimeTo] = useState(dayjs().set('hour', 5).set('minute', 59).set('second', 59));
    
    const handleGenerateBtn = () => {
        let tf = timeFrom['$d']
        let tf2 = tf.toString().split(' ')
        let tt = timeTo['$d']
        let tt2 = tt.toString().split(' ')
        setIsLoading(true)
        if(selectedDateFrom > selectedDateTo) {
            setIsLoading(false)
            Toastify({
                text: `Invalid Date Range.`,
                className: 'toast-error',
                position: "right"
            }).showToast()
            setData([])
        }
        else if(accountType === "") {
            setIsLoading(false)
            Toastify({
                text: `Account Type is required field.`,
                className: 'toast-error',
                position: "right",
            }).showToast()
            setData([])
        }
        else {
            let dateTimeFrom = `${selectedDateFrom} ${tf2[4]}`
            let dateTimeTo = `${selectedDateTo} ${tt2[4]}`
            
            let result = new Promise((resolve, reject) => {
                axios.get(`/api/fastwin/redthunder/transactions?dateFrom=${dateTimeFrom}&dateTo=${dateTimeTo}&accountType=${accountType}&key=${process.env.REACT_APP_KEY}`)
                .then(res2 => resolve(res2))
                .catch(error => reject(error))
            })
            result.then((response) => {
                if(response.data.results === null) {
                    setIsLoading(false)
                    Swal.fire({
                        icon: 'warning',
                        title: `No data found`,
                        text: `No data available during the period.`
                    })
                    setData([])
                }
                else {
                    setIsLoading(false)
                    setData(response.data.results)
                }
            }).catch((error) => {
                setIsLoading(false)
                Swal.fire({
                    icon: 'error',
                    title: `Code: ${error.response.data.responseCode}`,
                    text: `Error ${error.response.data.responseCode} - ${error.response.data.responseStatus}`
                })
                setData([])
            })
        }
    }

    const Logout = () => {
        Swal.fire({
            icon: 'warning',
            title: `LOGOUT?`,
            text: `Are you sure you want to logout?`,
            showCancelButton: true,
            confirmButtonText: 'Yes',
        })
        .then(result => {
            if(result.isConfirmed === true && result.isDismissed === false) {
                removeUser()
                setTimeout(() => {
                    window.location.href = "/"
                }, 1000)
            }
        })
    }

    const session = isLogged()

    if(session === null) {
        setTimeout(() => {
            window.location.href = "/"
        }, 2000)
        return(
            <Loading open={true} />
        )
    }
    else {
        return(
            <>
                <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
                <CssBaseline />
                <AppBar
                    position="static"
                    color="default"
                    elevation={0}
                    sx={{ background: 'transparent' }}
                    
                >
                    <Toolbar sx={{ flexWrap: 'wrap' }}>
                        <img src={logo} className="images-logo2"/>
                        <Typography variant="h6" color="white" noWrap sx={{ flexGrow: 1, p: 2 }}>
                            Back Office
                        </Typography>
                        <nav></nav>
                        <Button variant="contained" sx={{ my: 1, mx: 1.5, background: "rgb(188, 189, 191)", color: '#3b3939' }} className="btnLogout" onClick={Logout}>
                            Logout
                        </Button>
                    </Toolbar>
                </AppBar>
                <Container maxWidth='lg'>
                    <Paper elevation={3} sx={{ p: 2, m: 3 }}>
                        <p>Generate Reports</p>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack direction="row" spacing={2} sx={{ mt: 3, mb: 2 }}>
                                <TextField
                                    id="df"
                                    label="Date From"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={handleDateFromChange}
                                    defaultValue={selectedDateFrom}
                                    type="date"
                                    sx={{ width: 200 }}
                                />
                                <TimePicker
                                    ampm={false}
                                    openTo="hours"
                                    views={['hours', 'minutes', 'seconds']}
                                    inputFormat="HH:mm:ss"
                                    mask="__:__:__"
                                    label="Time From"
                                    value={timeFrom}
                                    onChange={(newValue) => {
                                        setTimeFrom(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                                <TextField
                                    id="dt"
                                    label="Date To"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={handleDateToChange}
                                    value={selectedDateTo}
                                    type="date"
                                    sx={{ width: 200 }}
                                />
                                <TimePicker
                                    ampm={false}
                                    openTo="hours"
                                    views={['hours', 'minutes', 'seconds']}
                                    inputFormat="HH:mm:ss"
                                    mask="__:__:__"
                                    label="Time To"
                                    value={timeTo}
                                    onChange={(newValue2) => {
                                        setTimeTo(newValue2);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                                <FormControl sx={{ width: 300 }}>
                                    <InputLabel id="account-type">Account Type</InputLabel>
                                    <Select
                                        labelId="account-type"
                                        id="account-type"
                                        value={accountType}
                                        label="Account Type"
                                        onChange={handleChangeType}
                                    >
                                        <MenuItem value={2}>All</MenuItem>
                                        <MenuItem value={0}>LandBased</MenuItem>
                                        <MenuItem value={1}>RGP</MenuItem>
                                    </Select>
                                </FormControl>
                                <Button variant="contained" onClick={handleGenerateBtn}>Generate</Button>
                            </Stack> 
                        </LocalizationProvider>
                    </Paper>
                </Container>
                <Paper elevation={3} sx={{ p: 2, m: 3 }}>
                    <TableData data={data}/>
                </Paper>
                <Loading open={isLoading}/>
                <Footer sx={{ mt: 6, mb: 4 }} />
                {/* <div style={{ position: "fixed", top: "100px", right: "0px", backgroundColor: "red", width: "180px", height: "50px" }}>
                    <p style={{ paddingTop: "13px", paddingLeft: "15px", color: "#fff", fontSize: "15px", }}>UAT Environment</p>
                </div> */}
            </>
        )
    }
}

export default BO