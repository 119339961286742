import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from 'react-router-dom';
import Login from './features/login';
import BO from './features/bo';
import { isLogged } from './utils';

const App = () => {
  const isLoggedIn = isLogged()
  return(
    <>
      <Router>
        <Switch>
          <Route exact path="/">
            { isLoggedIn ? <Redirect to="/redthunder" /> : <Redirect to="/login" /> }
          </Route>
          <Route path="/login" component={Login} />
          <Route path="/redthunder" component={BO} />
        </Switch>
      </Router>
    </>
  )
}

export default App;